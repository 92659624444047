function setEqualHeight() {
    $(".dobrodosli .grid-element-content").matchHeight();
    $(".enaka-visina").matchHeight();
    $(".enaka-visina-1").matchHeight();
    $(".enaka-visina-2").matchHeight();
    $(".enaka-visina-3").matchHeight();
    $(".enaka-visina-4").matchHeight();
    $(".grid-menu-item-010").matchHeight();

}
$(window).on("load", function() {
    setEqualHeight();
});
$(window).on("resize", function() {
    // setEqualHeight();
    $.fn.matchHeight._update();
});

$('#main-menu-state-fixed').on("change", function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});

$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit = 120,
        initial_height = 120,
        target_height = 80,
        initial_padding = 20,
        target_padding = 5,
        initial_logo_height = 175,
        target_logo_height = 80,
        initial_shadow_height = 45,
        target_shadow_height = 0,
        height,
        logo_height,
        margin_top = 0,
        padding,
        shadow_height;
    switch (current_breakpoint) {
        case "xxxlarge":
            scroll_limit = 120;
            initial_height = 120;
            target_height = 80;
            initial_padding = 20;
            target_padding = 5;
            initial_logo_height = 175;
            target_logo_height = 80;
            initial_shadow_height = 45;
            target_shadow_height = 0;
            break;
        case "xxlarge":
            scroll_limit = 120;
            initial_height = 120;
            target_height = 80;
            initial_padding = 20;
            target_padding = 5;
            initial_logo_height = 175;
            target_logo_height = 80;
            initial_shadow_height = 45;
            target_shadow_height = 0;
            break;
        case "large":
            scroll_limit = 100;
            initial_height = 100;
            target_height = 70;
            initial_padding = 15;
            target_padding = 5;
            initial_logo_height = 120;
            target_logo_height = 70;
            initial_shadow_height = 20;
            target_shadow_height = 0;
            break;
        case "medium":
            scroll_limit = 100;
            initial_height = 100;
            target_height = 80;
            initial_padding = 15;
            target_padding = 5;
            initial_logo_height = 145;
            target_logo_height = 80;
            initial_shadow_height = 45;
            target_shadow_height = 0;
            break;
        case "small":
            scroll_limit = 120;
            initial_height = 120;
            target_height = 80;
            initial_padding = 20;
            target_padding = 5;
            initial_logo_height = 175;
            target_logo_height = 115;
            break;
        case "xsmall":
            scroll_limit = 50;
            initial_height = 110;
            target_height = 110;
            initial_padding = 10;
            target_padding = 10;
            initial_logo_height = 175;
            target_logo_height = 115;
            break;
        default:
            scroll_limit = 120;
            initial_height = 120;
            target_height = 80;
            initial_padding = 20;
            target_padding = 5;
            initial_logo_height = 175;
            target_logo_height = 115;
            break;
    }
    if (current_breakpoint_num > 3) {
        if ($(window).scrollTop() === 0) {
            height = initial_height;
            padding = initial_padding;
            logo_height = initial_logo_height;
            shadow_height = initial_shadow_height;
        } else if ($(window).scrollTop() <= scroll_limit) {
            height = initial_height - (initial_height - target_height) * ($(window).scrollTop() / scroll_limit);
            padding = initial_padding - (initial_padding - target_padding) * ($(window).scrollTop() / scroll_limit);
            logo_height = initial_logo_height - (initial_logo_height - target_logo_height) * ($(window).scrollTop() / scroll_limit);
            shadow_height = initial_shadow_height - (initial_shadow_height - target_shadow_height) * ($(window).scrollTop() / scroll_limit);
        } else {
            height = target_height;
            padding = target_padding;
            logo_height = target_logo_height;
            shadow_height = target_shadow_height;
        }
        $("#header-wrapper").css("margin-top", "");
        $(".header-left").css("height", height + "px");
        $("#logo a").css("padding-top", padding + "px");
        $("#logo a").css("padding-bottom", padding + "px");
        $("#logo a").css("height", logo_height + "px");
        $("#logo .shadow").css("height", shadow_height + "px");
        // $("#header-main-wrapper").css("padding-top", padding + "px");
        // $("#header-main-wrapper").css("padding-bottom", padding + "px");
    } else if (current_breakpoint_num == 1) {
        if ($(window).scrollTop() === 0) {
            margin_top = 0;
        } else if ($(window).scrollTop() <= scroll_limit) {
            margin_top = 0 - $(window).scrollTop();
        } else {
            margin_top = -scroll_limit;
        }
        $("#header-wrapper").css("margin-top", margin_top + "px");
        $(".header-left").css("height", "");
        $("#logo a").css("padding-top", "");
        $("#logo a").css("padding-bottom", "");
        $("#logo a").css("height", "");
        $("#logo .shadow").css("height", "");
        // $("#header-main-wrapper").css("padding-top", padding + "px");
        // $("#header-main-wrapper").css("padding-bottom", padding + "px");
    } else {
        $("#header-wrapper").css("margin-top", "");
        $(".header-left").css("height", "");
        $("#logo a").css("padding-top", "");
        $("#logo a").css("padding-bottom", "");
        $("#logo a").css("height", "");
        $("#logo .shadow").css("height", "");
    }

    if ($(window).scrollTop() <= scroll_limit) {
        $("#header-wrapper").removeClass("fixed");
    } else {
        $("#header-wrapper").addClass("fixed");
    }
});
